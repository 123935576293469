import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import SEO from "../components/seo"

const SecondPage = () => (
	<Layout>
		<SEO
			title="Thank You"
			description="JKA Design LLC is passionate about helping Colorado home-owners improve their living space and achieving a higher quality lifestyle; one where their house is comfortable, efficient, and actually serves them."
		/>
		<div class="page-headline">
			<div class="container">
				<div class="section-heading text-center">
					<h1>
						<strong>Thank You</strong>
					</h1>
				</div>
			</div>
		</div>
		<div class="page-content">
			<div class="container">
				<div class="row justify-content-between align-items-center">
					<div class="col-md-12 text-center">
						<h2 className="mb-0	text-dark">
							<strong>
								We received your message and will get back to you shortly.
							</strong>
						</h2>
						<div className="mt-5">
							<Link className="btn-global offset2" to="/">
								Take me Home
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default SecondPage
